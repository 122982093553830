(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:OrderReplacementCardCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('OrderReplacementCardCtrl', OrderReplacementCardCtrl);

  function OrderReplacementCardCtrl(user, company, $scope, $state, FormControl, Order, $mdToast, $translate, $mdDialog, $document) {
    var vm = this
    , formControl = new FormControl();

    vm.name = '';
    vm.csn = '';

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var data = {
            id: user.id,
            companyId: company.id,
            name: vm.name,
            csn: vm.csn
          };

      formControl
        .process(Order.reorder(data).$promise)
        .then(function () {
          $mdDialog
          .show(
            $mdDialog
              .alert({focusOnOpen: false})
              .parent(angular.element($document.body))
              .clickOutsideToClose(true)
              .content($translate.instant('order.replacementCard.successText'))
              .ok($translate.instant('order.itemDialog.close'))
          );

          vm.name = '';
          vm.csn = '';

          $scope.orderReplacementCardForm.$setPristine();
          $scope.orderReplacementCardForm.$setUntouched();
        });
    };
  }
}());
